import FinanceApi from '@/services/FinanceApi';

const getByDate = async (date, currency) => {
    if(currency=='TRY'){
        return null;
    }
    return FinanceApi.get('/currency/rates/'+date+'/'+currency);
}

export default {
    getByDate
}
